import React from "react";
import Layout from "../../../Layout";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../page-style.scss";

const data = (function () {
  let imgArray = [];
  for (let i = 1; i <= 7; i++) {
    let imgUrl = `https://chinmayavvdelhi.ac.in/assets/images/calendar/18-oct-2/pic${i}.jpeg`;
    imgArray.push(imgUrl);
  }

  return imgArray;
})();

export default function GlobalHandwashingDay2023() {
  const settings = {
    infinite: true,
    arrows: true,
    autoplay: true,
    autoplaySpeed: 5000,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  function ImageSlider() {
    return (
      <div className="calendar-page__slider">
        <Slider {...settings}>
          {data.map((item, i) => (
            <div className="calendar-page__images__slide">
              <div className="calendar-page__images__card">
                <img alt="" src={item}></img>
              </div>
              <br />
              <center>
                <h3>{item.titleSubHead}</h3>
              </center>
            </div>
          ))}
        </Slider>
      </div>
    );
  }

  return (
    <Layout>
      {/* <SEO
        title="School Gallery | Chinmaya Vidyalaya Vasant Vihar"
        description="Explore photos and videos of the campus and student activities  at the Vidyalaya for a holistic view of student life."
      /> */}

      <div className="calendar-page">
        <div className="goback">
          <a href="/school-events/">
            <i className="fa-solid fa-arrow-left"></i>Go Back
          </a>
        </div>
        <h1 className="heading">Global Handwashing Day</h1>

        <p>
          <strong>"Cleanliness is next to Godliness"</strong>
          <br />
          <br />
          The Pre-Primary Department of Chinmaya Vidyalaya New Delhi organised a
          Global Hand Washing activity on 13th October 2023.” The pepper and
          soap experiment”, wherein how soap destroys viruses was shown to
          learners through this activity and to acquaint the little learners
          with the significance of keeping their hands germ-free a demo of
          proper step by step hand washing was given.
          <br />
          <br />
          Educating children about the importance of washing hands in a proper
          manner is the key to promote good health and well-being`
        </p>
        <section className="calendar-page__videosection">
          <ImageSlider />
        </section>
      </div>
    </Layout>
  );
}
